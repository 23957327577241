import { Button, Grid,  Typography } from '@mui/material'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import moment from 'moment/moment'



export default function WaitlistSucess() {

    let {courseName, courseDate} = useParams()

    return (
        <React.Fragment>
        <Grid item xs={12} mb={12} textAlign="center">
            <Typography variant='h2' component='h1' align='center' m={2} color="textPrimary" >Thank You!</Typography>
            <Typography variant='h6' component="p" align='center'>
                You have been added to the wait list for {courseName}
            </Typography><br />
            <Button variant='contained' component={Link} to='/courses' color='primary'>Courses</Button>
        </Grid>
        </React.Fragment>
    )
}

