import React, { useState } from 'react'
import AppBar from '@mui/material/AppBar'
import MenuIcon from '@mui/material/Menu'
import IconButton from '@mui/material/IconButton'
import { Button, Icon, SvgIcon, Toolbar, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Link } from 'react-router-dom'
import { Image } from '@mui/icons-material'
import logo from './logo-white-green-solid.svg'
export default function TopNav({links}) {
  return (
    <Box>
    <AppBar position='fixed' sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1
    }}>
        <Toolbar>
            <IconButton size='large' edge='start' color='inherit' aria-label='menu' sx={{mr:2}} open={true} component={Link} to='/'>
              <img src={logo} height={50} width={50} />
            </IconButton>
            <Typography variant='h6' noWrap component="div" flexGrow={1}>
                FVSD Driving School
            </Typography>
        </Toolbar>
    </AppBar>
    </Box>
      
  )
}
