import { Grid,  Typography } from '@mui/material'
import React from 'react'
import CourseList from './CourseList'
import MuiMarkdown from 'mui-markdown';



export default function Courses() {
    return (
        <React.Fragment>
        <Grid item xs={12} mb={12}>
            <Typography variant='h3' component='h1' align='center' m={2} color="textPrimary" >Courses</Typography>
            <Typography variant='h6' component="p" align='center'>
                View the list of courses offered at FVSD Driving School
            </Typography>

        </Grid>
        
        <CourseList />
        </React.Fragment>
    )
}

