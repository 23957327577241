import { Button, Card, CardActionArea, CardActions, CardContent, CardHeader, CircularProgress, Typography } from '@mui/material'
import PropTypes from 'prop-types';
import { Grid } from '@mui/material'
import { grey } from '@mui/material/colors'
import { Container } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import {getCourses} from '../api'
import moment from 'moment/moment'
import { withStyles } from 'mui-styles'

import {styles} from '../styles'



 function CourseList(props) {
  const { classes } = props;

  let [courses, setCourses] = useState([])
  useEffect(() => {
    getCourses()
    .then(response => setCourses(response))
    

  }, [])  

    const {content} = useParams()



      const cards = courses.map((course, index) => {
        return (
          <React.Fragment>
            <CardActionArea component={Link} to={course.id} >
              <CardHeader
                className={classes.cardHeader}
                title={course.name}
                subheader={course.description}
                titleTypographyProps={{ align:'center'}}
              ></CardHeader>
            </CardActionArea>
            <CardContent>
              {(course.hasAvailability ? (<>
              <Typography variant='h6' mt={2} color="textSecondary">Next Course Date</Typography>
              <Typography variant='h4' color="textPrimary">{moment(course.nextOffering).format("MMM DD, YYYY")}</Typography>
              </>
              ) : <>
              <Typography variant='h6' mt={2} color="textSecondary">No upcoming course dates</Typography>
              <Typography variant='h4' color="textPrimary"></Typography>
              </>)}
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Button fullWidth variant="contained" color="primary" component={Link} to={course.id}>{course.hasAvailability == true ? "Register Now" : "Join the wait list"}</Button>
            </CardActions>
        </React.Fragment>
            )
            
          }
          )

  return courses.length > 0 ? (
    <Container component={Grid} item sx={{minWidth:"900"}}>
        <Typography variant='subtitle1'>{content}</Typography>
        <Grid container flex={1} spacing={4} alignItems="flex-end" alignContent="center" align = "center" justifyContent = "center" >
     {cards.map((card, index) => (
         <Grid item sm={6} md={4} xs={12} key={index}>
        <Card sx={{height:'100%'}}>{card}</Card>
    </Grid>
  ))}
  </Grid>
    </Container>
  ) : (
    <Container sx={{display:'flex'}}>
    <Grid container spacing={2} justifyContent='center'>
                        <Grid item>

    <CircularProgress color='primary'></CircularProgress>
                        </Grid>
    </Grid>
</Container>
  )
}

CourseList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CourseList)
