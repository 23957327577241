import './App.css';
import TopNav from './TopNav'
import SidePanel from './SidePanel'
import { Box } from '@mui/system';
import { Container, createTheme, CssBaseline, Grid, ThemeProvider, Toolbar} from '@mui/material';
import CourseList from './components/CourseList';
import { BrowserRouter, Route, Routes, redirect, Navigate } from 'react-router-dom';
import Home from './components/Home';
import Courses from './components/Courses';
import CourseRegistration from './components/CourseRegistration';
import ReservationSuccess from './components/ReservationSuccess';
import WaitlistSucess from './components/WaitlistSuccess';


function App() {

  const theme = createTheme({
    palette: {
      type: 'light',
      primary: {
        main: '#1C3664',
      },
      secondary: {
        main: '#6fC067',
      },
    },
    typography: {
      fontWeightRegular: 500,
      h1: {
        fontSize: '3.3rem',
      },
      button: {
        fontWeight:600
      }
    },
  })

  const links = [
    {
        text: 'Courses',
        to: '/courses'
    },
]

  return (
    <ThemeProvider theme={theme}>

    <BrowserRouter>
    <CssBaseline/>
    <Container>

    <Box sx={{ display: 'flex'}}>
      <TopNav links={links}></TopNav>
      {/* <SidePanel isOpen={false} links={links}></SidePanel> */}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Grid container spacing={2}>
          <Routes>
            <Route path='/' element={<Navigate to="/courses" />}></Route>
            <Route path='/courses/' element={<Courses/>}></Route>
            <Route path='/reservationSuccess/:courseName/:courseDate' element={<ReservationSuccess/>}></Route>
            <Route path='/waitlistSuccess/:courseName' element={<WaitlistSucess/>}></Route>
            <Route path='/courses/:courseId' element={<CourseRegistration/>}></Route>
          </Routes>
        </Grid>
      </Box>
    </ Box>
    </Container>
    </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
