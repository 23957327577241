import { Alert, Backdrop, Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, CircularProgress, Divider, FormControl, Grid, Input, InputLabel, List, ListItem, ListItemText, MenuItem, Paper, Select, Skeleton, TextField, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { withStyles } from 'mui-styles'
import React, { useEffect, useState, useRef, createRef } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getCourse, registration } from '../api'
import { styles } from '../styles'
import moment from 'moment/moment'

function CourseRegistration(props) {
    const { classes } = props;
    let { courseId } = useParams()
    let [course, setCourse] = useState()
    let [courseOffering, setCourseOffering] = useState({})
    let [drivingSession, setDrivingSession] = useState({})
    let [email, setEmail] = useState()
    let [fullname, setFullname] = useState()
    let [phonenumber, setPhoneNumber] = useState()
    let [isError, setIsError] = useState(false)
    let navigate = useNavigate()
    let [submitting, setSubmitting] = useState(false)
    let [isWaitlist, setIsWaitlist] = useState(false)

    const [operatorLicenseFile, setOperatorLicenseFile] = useState()

    useEffect(() => {
        getCourse(courseId)
            .then(response => {
                setCourse(response)
                if(response.courseOfferings.length == 0) 
                    setIsWaitlist(true)
            })

    }, [])

    function handleCourseOfferingChange(event) {
        setCourseOffering(event.target.value)
    }
    function handleDrivingSessionChange(event) {
        setDrivingSession(event.target.value)
    }
    function handleCloseError() {
        setIsError(false)
    }

    async function handleSubmit(event) {
        event.preventDefault()
        setSubmitting(true)
        const result = registration({
            fullname,
            email,
            phonenumber,
            courseOfferingId: courseOffering.id,
            drivingSessionId: drivingSession?.id,
            courseId: course.id
        }, isWaitlist)
        .then(result => {
            setCourseOffering(null)
            setDrivingSession(null)
            event.target.reset()
            setSubmitting(false)
            if(isWaitlist) {
                navigate(`/waitlistSuccess/${course.name}`)
            } else {
                navigate(`/reservationSuccess/${course.name}/${courseOffering.date}`)
            }
        })
        .catch(error => {
            setSubmitting(false)
            setIsError(error)
        })

    }




    return course ? (
        <Container component={Grid} item sx={{minWidth:"900"}}>
        <React.Fragment>
                <Grid item xs={12} mb={12}>
                    <Typography variant='h3' component='h1' align='center' m={2} color="textPrimary" >{course.name}</Typography>
                    {course.description &&
                    <Typography variant='p' align='left' m={2} color="textSecondary" >{course.description}</Typography>
                }

                </Grid>

            </React.Fragment>
            <Box component="form" autoComplete="off" onSubmit={handleSubmit}>
                        <Grid container spacing={2} mb={2}>
                {(!isWaitlist ? <>
                <Grid item xs={12}>
                    <Typography variant='h4' gutterBottom align='left'>
                        Registration Form
                    </Typography>
                    <Divider variant=''></Divider>
                </Grid>
                            <Grid item xs={12}>
                                <Typography variant='h6' color='textPrimary' >Choose a Course Date</Typography>
                            </Grid>
                    <Grid item xs={12} sm={4}>
                        {course.courseOfferings &&
                            <TextField required fullWidth select id="courseoffering" label="Select Course Date" defaultValue='' onChange={handleCourseOfferingChange}>
                                {course?.courseOfferings?.map((courseOffering, index) => (
                                    <MenuItem key={courseOffering.id} ref={courseOffering} value={courseOffering}>{moment(courseOffering.date).format('MMM DD, YYYY')}</MenuItem>
                                ))}
                            </TextField>
                        }
                    </Grid>
                        {courseOffering?.drivingSessions?.length > 0 &&
                    <Grid item xs={12} sm={4}>
                            <TextField required fullWidth select id="drivingsession" label="Select Driving Session" defaultValue='' onChange={handleDrivingSessionChange}>
                                {courseOffering.drivingSessions.map((drivingsession, index) => (
                                    <MenuItem key={drivingsession.id} value={drivingsession}>{moment(drivingsession.startDate).format('MMM DD, YYYY')} - {moment(drivingsession.endDate).format('MMM DD, YYYY')} | {drivingsession.ampmFormatted}</MenuItem>
                                ))}
                            </TextField>

                    </Grid>
                        }
                        {courseOffering?.description && 
                        <Grid item xs={12}>
                            <Typography variant='p' color='textSecondary'>
                            {courseOffering.description}
                            </Typography>
                            </Grid>
                        }
                </> : <>
                <Grid item xs={12}>
                    <Typography variant='h4' gutterBottom align='left'>
                        Join the wait list
                    </Typography>
                    <Divider variant=''></Divider>
                </Grid>
                </>)}
                        <Grid item xs={12}>
                <Typography variant='h6' mt={2} >Enter Your Information</Typography>
                        </Grid>
                    <Grid className='testGrid' item xs={12}>
                        <TextField fullWidth required id="fullname" name='fullname' label="Full Name" onChange={e => setFullname(e.target.value)} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField required fullWidth id="phonenumber" name='phonenumber' label="Phone Number" onChange={e => setPhoneNumber(e.target.value )} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField type='email' fullWidth id="email" name='email' label="Email Address" onChange={e => setEmail(e.target.value )} />
                    </Grid>
                    <Box width='100%' />
                    <Grid item sm={9} display={{ xs: 'none', sm:'block'}} />
                    <Grid item xs={12} sm={3} justifyContent='right' justifyItems='right'textAlign='right' >
                        <Button fullWidth variant='contained' color='primary' type='submit' >Submit</Button>
                    </Grid>
                </Grid>
            </Box>
            <Backdrop open={submitting || isError}>
            {(submitting ? <CircularProgress color='primary'></CircularProgress> : null)}
            {(isError ? <Alert severity='error' closeText='OK' onClose={() => {handleCloseError()}}>{isError}</Alert> : null)}

            </Backdrop>
        </Container>
) : (    <Container component={Grid} item sx={{minWidth:"900"}}>

    <Grid container spacing={2} justifyContent='center'>
                        <Grid item>

    <CircularProgress color='primary'></CircularProgress>
                        </Grid>
    </Grid>
</Container>
    )
}

export default withStyles(styles)(CourseRegistration)