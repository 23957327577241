const apiURL = "/api"

export async function getCourses() {
    const response = await fetch(`${apiURL}/GetCourses`)
    const result  = await response.json()
    
    return result
    
}

export async function getCourse(courseId) {
    const response = await fetch(`${apiURL}/GetCourse/${courseId}`)
    const result  = await response.json()
    return result
}

export async function registration(data, waitList) {
   
    let endpointUrl = `${apiURL}/ReserveStudent`

    if(waitList) endpointUrl = `${apiURL}/AddToWaitlist`

    const response = await fetch(endpointUrl, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {"Content-Type": "application/json; charset=UTF-8"}
    }).then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson ? await response.json() : null;
        if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error)
        }
        return Promise.resolve(response)
    })
    const result = await response.json()

    return result
}
